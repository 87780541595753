import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';
import { SessionProvider } from '../context/SessionContext';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Popin | Meeting app"
        titleTemplate="%s"
        defaultTitle="Popin | Conference"
        description="upto 800 participants"
        twitter={{
          handle: '@popin',
          site: '@popin',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          url: 'https://popin.to',
          images: [
            {
              url: 'https://framerusercontent.com/images/fZrpyOzXMMHlEkSgHcQlrzyT1c.png?scale-down-to=1024',
              width: 633,
              height: 524,
              type: 'image/png',
            },
          ],
          site_name: 'Popin',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.svg',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/logo.svg',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/images/logo.svg',
            color: '#070707',
          },
        ]}
      />
      <SessionProvider>
        <Component {...pageProps} />
      </SessionProvider>
    </>
  );
}

export default MyApp;
